/**
 * zdd 分享类型
 *
 * @type {{INVITE: number, SHARE_PROFILE: number}}
 */
export const SHARE_TYPE = {
  /**
   * 邀请 - 0
   */
  INVITE: 0,
  /**
   * 分享个人信息 授权 - 1
   */
  SHARE_PROFILE: 1,
  /**
   * 分享加入签署
   */
  JOIN_SIGN: 2,
}

/**
 * 申请查看记录状态
 *
 * @type {{SUCCESS: number, REJECT: number, EXPIRED: number, PENDING: number, INVALID: number}}
 */
export const RECORD_VIEW_STATUS_TYPE = {
  /**
   * 申请中 - 0
   */
  PENDING: 0,
  /**
   * 已同意可查看 - 1
   */
  SUCCESS: 1,
  /**
   * 已驳回 - 2
   */
  REJECT: 2,
  /**
   * 已过期 - 3
   */
  EXPIRED: 3,
  /**
   * 申请失效 - 4
   */
  INVALID: 4,
}
/**
 * 申请查看记录状态 - 文本
 *
 * @type {{[p: string]: string, [p: number]: string}}
 */
export const RECORD_VIEW_STATUS_TYPE_TEXT = {
  [RECORD_VIEW_STATUS_TYPE.PENDING]: '申请中',
  [RECORD_VIEW_STATUS_TYPE.SUCCESS]: '可查看',
  [RECORD_VIEW_STATUS_TYPE.REJECT]: '已驳回',
  [RECORD_VIEW_STATUS_TYPE.EXPIRED]: '已过期',
  [RECORD_VIEW_STATUS_TYPE.INVALID]: '申请失效',
}

/**
 * 查看记录方式 类型
 *
 * @type {{AUTH: number, APPLY: number}}
 */
export const RECORD_VIEW_TYPE = {
  /**
   * 申请查看 - 0
   */
  APPLY: 0,
  /**
   * 授权查看 - 1
   */
  AUTH: 1,
}

/**
 * 查看记录方式 类型 - 文本
 *
 * @type {{[p: string]: string, [p: number]: string}}
 */
export const RECORD_VIEW_TYPE_TEXT = {
  [RECORD_VIEW_TYPE.APPLY]: '申请查看',
  [RECORD_VIEW_TYPE.AUTH]: '授权查看',
}
