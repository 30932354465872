import router from '@/router/index'
import { SHARE_TYPE } from '@/common/zddEnum'
/**
 * 授权查看档案
 */
export function authShare() {
  router.push({
    name: 'ZddShare',
    query: {
      type: SHARE_TYPE.SHARE_PROFILE,
    },
  })
}

/**
 * 邀请公司
 */
export function inviteShare() {
  router.push({
    name: 'ZddShare',
    query: {
      type: SHARE_TYPE.INVITE,
    },
  })
}

/**
 * 分享 加入签署
 *
 * @param businessId
 * @param partnerId
 */
export function inviteJoinSign({ businessId, partnerId }) {
  router.push({
    name: 'ZddShare',
    query: {
      type: SHARE_TYPE.JOIN_SIGN,
      businessId,
      partnerId,
    },
  })
}
