<template>
  <div class="launch-success-page">
    <div class="icon">
      <div class="title">已发起，等待参与方签署</div>
      <SvgIcon type="launch_success" class="launch_success" />
      <div class="tip" v-if="isFirst == 1">
        您还没有完成签署，建议您继续操作
      </div>
    </div>

    <div class="invite-partner-list-container" v-if="isZdd">
      <div class="invite-partner-list-container-title">
        邀请参与方（非必须，已短信通知）
      </div>
      <van-button
        type="primary"
        plain
        class="invite-partner-list-item"
        v-for="partner in needInvitePartnerList"
        :key="partner.partnerIndex"
        @click="handleToSharePage(partner)"
      >
        <div class="invite-partner-list-item-name">
          {{ getCurPartnerName(partner) }}
        </div>
        <div class="invite-partner-list-item-button">
          邀请签署 <van-icon name="arrow" />
        </div>
      </van-button>
    </div>

    <div class="button-group">
      <!-- <van-button type="primary" round @click="handleCreateLaunch">
        再发起一个</van-button
      > -->
      <van-button type="primary" v-if="isFirst == 1" @click="handleToDetail">
        查看并签署
      </van-button>

      <van-button type="primary" class="mt60" plain @click="handleToDetail"
        >查看签署详情</van-button
      >
      <van-button type="primary" plain @click="handleToHome"
        >返回首页</van-button
      >
    </div>
  </div>
</template>

<script>
  import { createSimpleLaunch } from '@/model/launch'
  import contractBusinessApi from '@/api/contractBusiness'
  import { PARTNER_ROLE } from '@/common/enum'
  import { inviteJoinSign } from '@/utils/zdd'

  export default {
    name: 'LaunchSuccess',
    data() {
      return {
        businessId: undefined,
        // 是否当前发起方为第一个参与方 0否 1是
        isFirst: undefined,
        needInvitePartnerList: [],
      }
    },
    computed: {
      isZdd() {
        return this.$store.getters['system/isZdd']
      },
    },
    created() {
      this.getQuery()
      this.getBusinessDetail()
    },
    methods: {
      getQuery() {
        this.businessId = this.$route?.query?.businessId
        this.isFirst = this.$route?.query?.isFirst
      },
      handleToDetail() {
        this.$router.push({
          name: 'SignDetail',
          query: {
            businessId: this.businessId,
          },
        })
      },
      handleCreateLaunch() {
        createSimpleLaunch()
      },
      handleToHome() {
        if (this.isZdd) {
          this.$router.replace({
            name: 'ZddHome',
          })
        } else {
          this.$router.replace({
            path: '/list',
          })
        }
      },

      // 邀请按钮 获取当前参与方的名字
      getCurPartnerName(partner) {
        const {
          comName,
          defaultName,
          customName,
          handlerName,
          handlerTel,
          partnerRole,
        } = partner
        console.log(partner)
        // 当前的参与方的信息
        let name
        if (partnerRole === PARTNER_ROLE.PARTNER_PERSONAL) {
          // 外部个人
          name = handlerName || handlerTel || '未知个人'
        } else if (partnerRole === PARTNER_ROLE.PARTNER_COM) {
          // 外部企业
          name = comName || '未知企业'
        }
        return `邀请${customName || defaultName}（${name}）`
      },

      async getBusinessDetail() {
        let loadingInstance
        try {
          loadingInstance = this.$toast.loading({
            message: '加载中',
            loadingType: 'spinner',
            duration: 90000,
          })
          const model = {
            businessId: this.businessId,
          }
          const { partnerList } = await contractBusinessApi.detail(model)
          const needInvitePartnerList = partnerList.filter(
            partner => partner.partnerRole !== PARTNER_ROLE.LAUNCHER
          )
          this.needInvitePartnerList = needInvitePartnerList
        } catch (error) {
          console.error(error)
        } finally {
          loadingInstance && loadingInstance.close()
        }
      },
      handleToSharePage(partner) {
        const { partnerId } = partner
        inviteJoinSign({ businessId: this.businessId, partnerId })
      },
    },
  }
</script>

<style lang="less" scoped>
  .launch-success-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    .icon {
      margin-top: 36px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .launch_success {
        font-size: 96px;
      }
      .title {
        font-size: 16px;
        color: #111a34;
        line-height: 24px;
        font-weight: 500;
      }
      .tip {
        margin-top: 32px;
        font-size: 14px;
        color: #111a34;
      }
    }

    .invite-partner-list-container {
      display: flex;
      flex-direction: column;
      width: 280px;
      margin-bottom: 9px;
      .invite-partner-list-container-title {
        font-size: 14px;
        font-weight: 500;
        margin: 16px 0;
        text-align: center;
      }
      .invite-partner-list-item {
        display: flex;
        width: 100%;
        .van-button__content {
          width: 100%;
          display: flex;
          .van-button__text {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 500;
          }
        }
      }
      .invite-partner-list-item + .invite-partner-list-item {
        margin-top: 16px;
      }
    }

    .button-group {
      margin-top: 11px;
      display: flex;
      flex-direction: column;
      width: 280px;
      .van-button {
        width: 100%;
        font-size: 15px;
        font-weight: 500;
      }
      .van-button + .van-button {
        margin-top: 20px;
      }
    }
  }
</style>
